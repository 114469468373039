.myNav {
  border-bottom: 1px solid #eeee;
}

.site-logo {
  height: 60px;
}

.site-logo2 {
  height: 73px;
}

/* sve za navigaciju */

.nav-link {
  text-decoration: none !important;
  color: var(--green) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.nav-items {
  display: flex;
}

.active-route {
  color: var(--red) !important;
  font-weight: 600 !important;
}

/* sve za my account */
.my-account-div {
  display: flex;
  justify-content: flex-end;
}

.my-account-content {
  display: flex;
  align-items: center;
}

.my-account-text {
  color: gray;
  font-size: 15px;
  margin-left: 8px;
}

.account-logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.cart-icon {
  font-size: 24px;
  position: relative;
}

.cartTotalNumber {
  font-size: 15px;
  top: 0;
  position: absolute;
}

.cartName {
  font-size: 15px;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  margin-top: 15px;
  text-align: center;
}

.login-button {
  margin-left: 10px;
  border: none;
  background-color: var(--green);
  padding: 9px 30px;
  border-radius: 5px;
  color: white;
}

.login-button:focus {
  color: white;
}
.login-button:hover {
  color: white;
  transition: all 500ms;
  background-color: var(--darkgreen);
}

.toProfile {
  margin-left: 20px;
  margin-top: 2px;
}

.toProfile:hover {
  color: var(--green);
}
