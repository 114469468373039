footer {
  background-color: #eaeaec38;
  padding-top: 50px;
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.footerTexts {
  margin-top: 25px;
}

.oneFuterItemText {
  font-size: 18px;
  margin-top: 10px;
}

.oneFuterItemIcon {
  margin-right: 15px;
  color: var(--green);
}

.copyrightBar {
  background-color: #c5272e;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
}
