.myOrdersScreen {
  min-height: 100vh;
  margin-top: 30px;
  margin-bottom: 50px;
}

.noProductsMessageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.noItemsInCartText {
  margin: 10px 0;
  text-align: center;
}

.shopNowButton {
  border: none;
  background-color: var(--green);
  padding: 9px 30px;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
}

.shopNowButton:focus {
  color: white;
}

.shopNowButton:hover {
  color: white;
  transition: all 500ms;
  background-color: var(--darkgreen);
}

.myOrdersHeading {
  margin-top: 50px;
  margin-bottom: 30px;
}

.oneMoreSpan {
  font-weight: bold;
}

.activeSort {
  background-color: rgb(216, 216, 216);
}

.paginationWrapper {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}
