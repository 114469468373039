.updateProfilePage {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.myInput {
  color: #696464;
  height: 50px;
  background: #f9f9f9;
  font-size: 15px;
  border-color: rgba(222, 213, 213, 0.4);
}

.myInput:focus {
  border-color: rgba(222, 213, 213, 0.4);
}

.signInText {
  margin-bottom: 30px;
}

.productListHeading {
  margin: 30px 0;
}
