.editProductScreen {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.editProductHeading {
  margin: 30px 0;
}

.trueButton {
  border: none;
  background-color: var(--green);
  border-radius: 5px;
  color: white;
  margin-top: 10px;
  width: 130px;
  height: 45px;
  font-size: 15px;
  margin-right: 10px;
}

.falseButton {
  border: none;
  background-color: var(--disabledButton);
  width: 130px;
  height: 45px;
  font-size: 15px;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
  cursor: not-allowed;
  margin-right: 10px;
}

.trueButton:hover {
  background-color: var(--darkgreen);
  border-color: var(--darkgreen);
  transition: all 500ms;
}
