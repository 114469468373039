.sliderCatchPhraseDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.smallHeading {
  color: var(--red);
}

.bigHeading {
  font-weight: bold;
  font-size: 50px;
}

.textAfter {
  border-left: 4px solid var(--green);
  padding-left: 10px;
  margin-top: 30px;
}

.buttonPart {
  margin-top: 40px;
}

.startShippingButton {
  background-color: var(--green);
  color: white;
  padding: 10px 40px;
  border-radius: 5px;
}

.startShippingButton:hover {
  color: white;
  transition: all 500ms;
  background-color: var(--darkgreen);
}

@media screen and (max-width: 1324px) {
  .bigHeading {
    font-size: 24px;
  }
  .sliderCatchPhraseDiv {
    margin-bottom: 70px;
  }
}
