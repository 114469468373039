.ordersScreen {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.ordersHeading {
  margin: 30px 0;
}

.activeSort {
  background-color: rgb(216, 216, 216);
}

.oneMoreSpan {
  font-weight: bold;
}

.paginationWrapper {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}
