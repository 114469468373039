.notVerifiedScreen {
  margin-top: 70px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.noProductsMessageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noItemsInCartText {
  margin: 10px 0;
  text-align: center;
}

.shopNowButton {
  border: none;
  background-color: var(--green);
  padding: 9px 30px;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
}

.shopNowButton:focus {
  color: white;
}

.shopNowButton:hover {
  color: white;
  transition: all 500ms;
  background-color: var(--darkgreen);
}
