.productScreen {
  margin: 60px 0;
  position: relative;
}

.productGridRow {
  border-left: solid 1px #e1e1e1;
}

.productGridColumn {
  border-bottom: solid 1px #e1e1e1;
  border-right: solid 1px #e1e1e1;
}

.oneProduct {
  margin: 20px 4px;
  border-radius: 10px;
  /* background-color: #fbfbfb; */
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.productTitle {
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  font-weight: normal;
  min-height: 55px;
}

.latestArrivalsHeading {
  margin-bottom: 15px;
  border-left: 4px solid var(--green);
  padding-left: 10px;
}

.bestGroupsHeading {
  margin-bottom: 30px;
  border-left: 4px solid var(--green);
  padding-left: 10px;
}

.mostOrderedHeading {
  margin-bottom: 15px;
  margin-top: 50px;
  border-left: 4px solid var(--green);
  padding-left: 10px;
}

.addToCartDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 30px;
}

.addToCartBtn {
  background: none;
  border: 1px solid var(--green);
  border-radius: 5px;
  color: var(--green);
  height: 40px;
  width: 100%;
}

.addToCartBtn:hover {
  background-color: var(--green);
  border: 1px solid var(--green);
  transition: all 200ms;
  color: white;
  transition: all 500ms;
}

.viewAllProductsDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.viewAllProductsButton {
  background-color: none;
  border: 1px solid var(--green);
  color: var(--green);
  padding: 10px 40px;
  border-radius: 5px;
}

.viewAllProductsButton:hover {
  background-color: var(--green);
  border: 1px solid var(--green);
  transition: all 500ms;
  color: white;
  padding: 10px 40px;
  border-radius: 5px;
}

.groupsContainer {
  margin: 30px 0;
}

.commonGroupCard1 {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(197, 39, 46, 0.1);
  padding: 20px 0;
}

.commonGroupCard2 {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(27, 154, 62, 0.1);
  padding: 20px 0;
}

.commonGroupCard3 {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #eaeaec38;
  padding: 20px 0;
}

.commonGroupImage {
  width: 200px;
  height: 200px;
}
.commonGroupTextDiv {
  margin-left: 15px;
}

.commonGroupText1 {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: bold;
  color: rgba(197, 39, 46, 0.4);
}

.commonGroupText2 {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: bold;
  color: rgba(27, 154, 62, 0.4);
}

.commonGroupText3 {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: bold;
  color: rgb(195 195 199);
}

.shopNowButton1 {
  background-color: rgba(197, 39, 46, 0.4);
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
}

.shopNowButton2 {
  background-color: rgba(27, 154, 62, 0.4);
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
}

.shopNowButton3 {
  background-color: rgb(195 195 199);
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
}

.shopNowButton1:hover {
  color: white;
}
.shopNowButton2:hover {
  color: white;
}
.shopNowButton3:hover {
  color: white;
}

.productPrice {
  font-weight: bold;
  margin-top: 23px;
  font-size: 18px;
  text-align: right;
}

.foundedProductsHeading {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e1e1e1;
  line-height: 0.1em;
  margin: 10px 0 20px;
  color: #817f7f;
}

.foundedProductsHeadingSpan {
  background: #fff;
  padding: 0 40px;
}

.sortByDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

.sortByHeading {
  margin-right: 10px;
}

.activeSort {
  background-color: rgb(216, 216, 216);
}

.categoriesDiv {
  margin-top: 30px;
}

.commonProductsFilterHeading {
  margin-bottom: 15px;
}

.productCard {
  border: none;
}

.productCardBody {
  min-height: 370px;
}

.productCardFooter {
  background: none;
  border-top: 0;
}

.discoundLabel {
  text-transform: uppercase;
  padding: 5px 15px;
  display: inline-block;
  position: absolute;
  top: 15px;
  border-radius: 5px;
  right: -10px;
  /* transform: rotate(45deg); */
  background-color: var(--green);
  color: #fff;
  font-size: 15px;
  z-index: 10;
}

.paginationWrapper {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}

.pdfButton {
  border: 1.9px solid green;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 6px;
  border-radius: 5px;
  color: #c4282e;
}

.pdfIcon {
  margin-right: 5px;
}

@media screen and (max-width: 1324px) {
  .addToCartDiv {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .addToCartBtn {
    margin-top: 10px;
    padding: 10px;
  }

  .commonGroupImage {
    width: 130px;
    height: 130px;
  }

  .commonGroupCard1 {
    justify-content: center;
    margin-bottom: 20px;
  }

  .commonGroupCard2 {
    justify-content: center;
    margin-bottom: 20px;
  }

  .commonGroupCard3 {
    justify-content: center;
  }

  .commonGroupTextDiv {
    margin-left: 15px;
  }
}

@media screen and (max-width: 800px) {
  .commonGroupTextDiv {
    margin-left: 45px;
  }
  .paginationWrapper {
    flex-direction: column;
  }
}
