.checkoutProductsDiv {
  background-color: white;
  min-height: 100vh;
  padding-top: 50px;
}

.checkoutTotalDiv {
  background-color: white;
  min-height: 100vh;
  padding-top: 50px;
  box-shadow: -5px 0 5px -5px #bfc0c2;
}

.checkoutHeader {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.shippingDetailsHeader {
  margin-bottom: 35px;
}

.itemsInCartHeader {
  margin-right: 10px;
}

.itemsInCartHeaderSpan {
  cursor: pointer;
  color: var(--green);
}

.commonCheckouBillingText {
  font-size: 16px;
  margin: 10px 0;
}

.checkoutCompanyName {
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
}

.bilingIcon {
  margin-right: 5px;
}

.orderPriceHeading {
  margin-top: 35px;
  margin-bottom: 35px;
}

.commonPriceDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.commonPriceDiv {
  margin: 5px 0;
}

.price {
  font-weight: bold;
  font-size: 15px;
}

.subtotalHeading {
  font-size: 15px;
}

.taxHeading {
  font-size: 14px;
}

.totalHeading {
  font-size: 17px;
  font-weight: bold;
}

.taxPrice {
  color: gray;
}

.totalPrice {
  font-size: 19px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .checkoutTotalDiv {
    box-shadow: 0 -10px 18px -20px #bfc0c2;
  }
}
