.checkout-one-product {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.checkoutScreen {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

/* .cartContainer {
  margin-top: 30px;
} */

.shoppingCartHeading {
  margin-right: 10px;
}

.heading {
  display: flex;
  padding: 20px;
  padding-bottom: 0;
}

.heading button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.heading_flag {
  flex: 1;
  margin-right: 16px;
}

.heading_name,
.heading_population,
.heading_gini {
  flex: 4;
  color: var(--text-color-secondary);
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.heading_name {
  justify-content: flex-start;
}

.row {
  display: flex;
  padding: 20px;

  text-align: center;

  background-color: var(--background-color-light);
  border-radius: 8px;
  margin-bottom: 16px;

  box-shadow: var(--box-shadow);
  font-weight: 500;

  transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out;
  align-items: center;
}

.row:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.name {
  flex: 4;
  text-align: left;
}

.population {
  flex: 4;
}

.gini {
  flex: 4;
}

.flag {
  flex: 1;
  margin-right: 16px;
}

.removeFromCartIcon {
  color: var(--red);
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
}

.billingHeading {
  margin-bottom: 15px;
}

.billingForm {
  background-color: var(--formBackground);
  min-height: 500px;
  border-radius: 10px;
  padding: 25px;
}

.placeOrderButton {
  background-color: var(--green);
  border-color: var(--green);
  display: block;
  width: 100%;
  text-align: center;
  color: white;
  padding: 9px 30px;
}

.placeOrderButton:hover {
  background-color: var(--darkgreen);
  border-color: var(--darkgreen);
  color: white;
}

.placeOrderButtonDisabled {
  background-color: var(--disabledButton) !important;
  border-color: var(--disabledButton);
  display: block;
  width: 100%;
  text-align: center;
  color: white;
  padding: 9px 30px;
  cursor: not-allowed;
}

.myInput {
  color: #696464;
  height: 50px;
  background: white;
  font-size: 15px;
  border-color: rgba(222, 213, 213, 0.4);
}

.myInput:focus {
  border-color: rgba(222, 213, 213, 0.4);
}

.totalPriceHeading {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.noProductsMessageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.noItemsInCartText {
  margin: 10px 0;
  text-align: center;
}

.shopNowButton {
  border: none;
  background-color: var(--green);
  padding: 9px 30px;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
}

.shopNowButton:focus {
  color: white;
}

.shopNowButton:hover {
  color: white;
  transition: all 500ms;
  background-color: var(--darkgreen);
}

.itemsInCartHeaderSpan {
  cursor: pointer;
  color: var(--green);
}

.paginationWrapper {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}
