/* -- quantity box -- */

.quantity001 {
  display: inline-block;
  width: 100%;
}

.quantity001 .input-text001.qty001 {
  width: 100px;
  height: 41px;
  padding: 0 5px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #efefef;
}

.quantity001.buttons_added001 {
  text-align: left;
  position: relative;
  white-space: nowrap;
  vertical-align: top;
  display: flex;
  justify-content: center;
  width: 100%;
}

.quantity001.buttons_added001 input {
  display: inline-block;
  margin: 0;
  vertical-align: top;
  box-shadow: none;
}

.quantity001.buttons_added001 .minus001,
.quantity001.buttons_added001 .plus001 {
  padding: 7px 20px 8px;
  height: 41px;
  background-color: #ffffff;
  border: 1px solid #efefef;
  cursor: pointer;
}

.quantity001.buttons_added001 .minus001 {
  border-right: 0;
}

.quantity001.buttons_added001 .plus001 {
  border-left: 0;
}

.quantity001.buttons_added001 .minus001:hover,
.quantity001.buttons_added001 .plus001:hover {
  background: #eeeeee;
}

.quantity001 input::-webkit-outer-spin-button,
.quantity001 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.quantity001.buttons_added001 .minus001:focus,
.quantity001.buttons_added001 .plus001:focus {
  outline: none;
}
