.productsListScreen {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.productListHeading {
  margin: 30px 0;
}

.addProductBtn {
  border-radius: 5px;
  background: var(--green);
  height: 45px !important;
}

.addProductBtn:hover {
  background: var(--darkgreen);
}
