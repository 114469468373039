.customersScreen {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.customersHeading {
  margin: 30px 0;
}

.paginationWrapper {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
}
