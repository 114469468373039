@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@400;600;700;800&display=swap");

:root {
  --red: #c5272e;
  --white: #ffffff;
  --green: #1b9a3e;
  --darkgreen: #0f5522;
  --formBackground: #f5f5f17d;
  --disabledButton: #95cca4;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Alegreya", serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.nav-link.dropdown-item {
  background: none;
}

a:hover {
  text-decoration: none !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

input,
textarea {
  -webkit-appearance: none;
}

.navbar-collapse {
  margin: 0 !important;
  padding: 0 !important;
}

.carousel-indicators li {
  background-color: var(--red);
}

.sslider .slick-slide {
  padding-right: 20px;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.dropdown-item {
  font-size: 15px;
}

button#dropdown-item-button-order {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: none;
  border: 2px solid var(--green);
  color: var(--green);
  font-size: 12px;
}

.dropdown {
  height: 100%;
}

.error-message {
  color: var(--red);
  margin-top: 5px;
}

#dropdown-item-button-order-products-screen {
  background: none;
  border: 2px solid var(--green);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  color: var(--green);
  font-size: 12px;
}

.loaderCSS {
  color: var(--darkgreen);
}

.list-group-item {
  padding-left: 0;
}

.btn {
  min-width: 150px;
}

.btn-success {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
}

.btn-outline-success {
  color: var(--green);
  border-color: var(--green);
}

.btn-outline-success:hover {
  color: white;
  border-color: var(--green);
  background-color: var(--green);
}

.notInTableButton {
  min-width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12.5px;
  background: transparent;
}

.notInTableButtonEditOrder {
  min-width: 150px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12.5px;
  margin-left: auto;
  background: transparent;
}

.list-group-item {
  cursor: pointer;
}

.react-select__control {
  border: none !important;
  padding: 4.5px;
  background-color: #f7f7f9 !important;
  padding-left: 15px;
  color: #55595c;
}

.react-select__single-value {
  color: #595d60 !important;
}

.react-select__control--is-focused {
  color: #595d60 !important;
  border: none !important;
}

.breadcrumb {
  padding-left: 0;
}

.flex {
  flex: 1;
}

.mySearchInput {
  flex: 1;
}

.confirmSearch {
  background-color: var(--green);
  border: 1px solid var(--green);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 13px;
  padding: 5px;
  color: white;
}

.abcsd.css-2b097c-container {
  width: 220px;
}

@media screen and (max-width: 600px) {
  .notInTableButtonEditOrder {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}
