.singleOrderScreen {
  margin-top: 30px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.orderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.orderHeaderLeft {
  display: flex;
  align-items: center;
}

.orderPaidStatus {
  background-color: rgba(27, 154, 62, 0.1);
  color: rgba(27, 154, 62, 0.5);
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12.5px;
  margin-right: 10px;
  margin-left: 20px;
}

.orderDeliverStatus {
  background-color: rgba(197, 39, 46, 0.1);
  color: rgba(197, 39, 46, 0.5);
  font-weight: bold;
  font-size: 12.5px;
  padding: 5px 15px;
  border-radius: 5px;
}

.heading {
  display: flex;
  padding: 20px;
  padding-bottom: 0;
}

.heading button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.heading_flag {
  flex: 1;
  margin-right: 16px;
}

.heading_name,
.heading_population,
.heading_gini {
  flex: 4;
  color: var(--text-color-secondary);
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
}

.heading_name {
  justify-content: flex-start;
}

.row {
  display: flex;
  padding: 20px;

  text-align: center;

  background-color: var(--background-color-light);
  border-radius: 8px;
  margin-bottom: 16px;

  box-shadow: var(--box-shadow);
  font-weight: 500;

  transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out;
  align-items: center;
}

.row:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.name {
  flex: 4;
  text-align: left;
}

.population {
  flex: 4;
}

.gini {
  flex: 4;
}

.flag {
  flex: 1;
  margin-right: 16px;
}

.customerDiv {
  margin-top: 20px;
}

.customerDataDiv {
  padding-bottom: 20px;
}

.commonDiv {
  border-top: 1px solid #dcd9d952;
  padding: 20px 0;
}

.oneData {
  margin-top: 12px;
}

.commonOneDataText {
  margin: 7px 0;
}

@media screen and (max-width: 600px) {
  .orderHeaderLeft {
    flex-direction: column;
  }
  .orderPaidStatus {
    margin: 10px 0;
  }
}
