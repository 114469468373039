.myInput {
  color: #696464;
  height: 50px;
  background: #f9f9f9;
  font-size: 15px;
  border-color: rgba(222, 213, 213, 0.4);
}

.myInput:focus {
  border-color: rgba(222, 213, 213, 0.4);
}

.loginPage {
  min-height: 100vh;
  padding-bottom: 100px;
}

.loginForm {
  margin-top: 50px;
}

.signInText {
  margin-bottom: 30px;
}

.loginButton {
  border: none;
  background-color: var(--green);
  width: 130px;
  height: 50px;
  font-size: 15px;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
}

.loginButtonDisabled {
  border: none;
  background-color: var(--disabledButton);
  width: 130px;
  height: 50px;
  font-size: 15px;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
  cursor: not-allowed;
}

.loginButton:hover {
  background-color: var(--darkgreen);
  border-color: var(--darkgreen);
  transition: all 500ms;
}

.commonGoToDiv {
  display: flex;
  justify-content: flex-end;
}

.commonGoTLink {
  color: var(--darkgreen);
}

.commonGoTLink:hover {
  color: var(--darkgreen);
}

.passwordGroup {
  position: relative;
}

.eyeIcon {
  position: absolute;
  top: 0;
  right: 0;
}
